'use client'

import { Button } from '../ui/button'
import { ConnectKitButton } from 'connectkit';

type LoginButtonProps = {
  text?: string
}

export default function LoginButton({ text = 'Login' }: LoginButtonProps) {

  return (

    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
        return (
          <button
            className="static rounded-3xl  ckbutton min-w-60 text-black min-h-14  border-solid border-[rgba(230,230,232,0.12)] [background:radial-gradient(100%_100%_at_50%_0%,rgba(255,255,255,0.12)_0%,rgba(255,255,255,0.02)_74.01%)] backdrop-blur-[30px]"
            onClick={show}
            style={{
              color: 'var(--Text-Primary, rgba(255, 255, 255, 0.96))',
              textAlign: 'center',
              fontFamily: '"Helvetica"',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
              letterSpacing: '-0.073px'
            }}
          >
  
              



              {/* <div className="bsolute rounded-lg  nt-6 w-3/5 z-50 h-full bg-white">
               <p className="w-full h-full">Teleport</p>
              </div> */}
            Enter

          </button>
  
        );
      }}
    </ConnectKitButton.Custom>


  )
}
