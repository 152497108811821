

import React from 'react';

function OrangeStarIcon(props) {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
        <path d="M52.9971 25.5546L45.2443 23.3191C38.1086 21.2762 32.6314 15.803 30.5871 8.67238L28.3886 0.925053C28.0414 -0.308351 25.9971 -0.308351 25.65 0.925053L23.4514 8.67238C21.4071 15.803 15.93 21.2762 8.79429 23.3191L1.04143 25.5546C0.424287 25.7088 0 26.2869 0 26.9422C0 27.5974 0.424287 28.137 1.04143 28.3298L8.79429 30.5653C15.93 32.6081 21.4071 38.0814 23.4514 45.212L25.65 52.9593C25.8429 53.576 26.3829 54 27 54C27.6171 54 28.1957 53.576 28.35 52.9593L30.5486 45.212C32.5929 38.0814 38.07 32.6081 45.2057 30.5653L52.9586 28.3298C53.5757 28.1756 54 27.5974 54 26.9422C54 26.2869 53.5757 25.7473 52.9586 25.5546H52.9971Z" fill="#F3A43D"/>
      </svg>
    )
}

export default OrangeStarIcon;